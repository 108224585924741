<template>
    <div class="missed-trades-widget">
        <div class="row mb-3">
            <div class="col-12 d-flex align-items-center justify-content-end">
                <b-button variant="light" class="mr-auto" @click="onExport">
                    Export
                </b-button>
                <b-button v-b-modal.missedTradesColumnsModal variant="light">
                    Columns
                </b-button>
            </div>
        </div>
        <b-table id="missed-trades-table" :items="filterItems()" :fields="getEnabledColumns()" responsive class="text-center" show-empty :per-page="50" :current-page="currentPage" sort-by="exitTime" :sort-desc="true">
            <template slot="top-row" slot-scope="{ fields }">
                <td v-for="field in fields" :key="field.key">
                    <input type="text" v-model="filters[field.key]" placeholder="Search..." class="form-control" />
                </td>
            </template>
            <template slot="empty">
                No results found
            </template>
        </b-table>
        <div class="row mt-3">
            <div class="col-12 d-flex align-items-center justify-content-start">
                <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="50" aria-controls="missed-trades-table"></b-pagination>
            </div>
        </div>
        <b-modal id="missedTradesColumnsModal" title="Customise Columns" body-class="p-0" @show="setupColumnFiltersModal" @ok="finishColumnFilters" @hidden="resetColumnFilters">
            <draggable v-model="filteredColumns">
                <div v-for="column in filteredColumns" :key="column.key" class="column-filter" @click="onColumnFilterClick($event, column)">
                    <div class="d-flex align-items-center justify-content-between">
                        <b-form-checkbox v-model="column.enabled" :value="true" :unchecked-value="false">
                            {{column.label}}
                        </b-form-checkbox>
                        <span class="drag-handle">
                            <i class="mdi mdi-menu"></i>
                        </span>
                    </div>
                </div>
            </draggable>
            <template v-slot:modal-footer="{ ok, cancel }">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-6 d-flex flex-row align-items-center justify-content-start">
                            <b-button variant="primary" @click="ok()">Confirm</b-button>
                            <b-button variant="secondary" @click="cancel()" class="ml-2">Cancel</b-button>
                        </div>
                        <div class="col-md-6 d-flex flex-row align-items-center justify-content-end">
                            <b-checkbox v-model="columnFiltersSave" :value="true" :unchecked-value="false">
                                Save as preset
                            </b-checkbox>
                        </div>
                    </div>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { map, clone } from 'lodash';
import { parse, format } from 'date-fns';
import { exportCsv } from '@/lib/Exporting';

export default {
    name: 'missed-trades',
    data() {
        let that = this;

        let columns = [
            {
                key: 'strategy',
                label: 'Strategy',
                sortable: true,
                enabled: false
            },
            {
                key: 'instrument',
                label: 'Instrument',
                sortable: true,
                enabled: false
            },
            {
                key: 'tradeDirection',
                label: 'Type',
                sortable: true,
                enabled: false
            },
            {
                key: 'size',
                label: 'Size',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return that.$options.filters.number(value, 0);
                }
            },
            {
                key: 'entryPrice',
                label: 'Entry',
                sortable: true,
                enabled: false
            },
            {
                key: 'exitPrice',
                label: 'Exit',
                sortable: true,
                enabled: false
            },
            {
                key: 'returnBps',
                label: 'Return (Bps)',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return that.$options.filters.number(value);
                }
            },
            {
                key: 'tradeReturn',
                label: 'Trade Return',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return that.$options.filters.percentage(that.$options.filters.number(value));
                }
            },
            {
                key: 'fundReturn',
                label: 'Fund Return',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return that.$options.filters.percentage(that.$options.filters.number(value));
                }
            },
            {
                key: 'volume',
                label: 'Volume',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return that.$options.filters.currency(that.$options.filters.number(value));
                }
            },
            {
                key: 'entryTime',
                label: 'Entry Time',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return format(value, 'dd/MM/yyyy HH:mm:ss');
                }
            },
            {
                key: 'exitTime',
                label: 'Exit Time',
                sortable: true,
                enabled: false,
                formatter: function(value) {
                    return format(value, 'dd/MM/yyyy HH:mm:ss');
                }
            }
        ];

        let sortedColumns = [];

        let loadedColumns = localStorage.getItem('missedTradesColumns');
        if(loadedColumns !== null) {
            loadedColumns = JSON.parse(loadedColumns);

            for(let key in loadedColumns) {
                for(let column of columns) {
                    if(column.key === key) {
                        column.enabled = loadedColumns[key];
                        sortedColumns.push(column);
                        break;
                    }
                }
            }
        } else {
            for(let column of columns) {
                column.enabled = true;
            }

            sortedColumns = columns;
        }

        let filters = {};
        for(let column of sortedColumns) {
            filters[column.key] = '';
        }

        return {
            columns: sortedColumns,
            items: [],
            filters: filters,
            columnFiltersSave: true,
            filteredColumns: [],
            currentPage: 1,
            hasSetupListeners: false
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        }
    },
    components: {
        draggable
    },
    mounted() {
        this.requests();
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('missingtrades', this.onMissedTrades);
    },
    methods: {
        requests() {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('missingtrades', this.onMissedTrades);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'MissingTrades',
                args: {}
            });
        },
        onMissedTrades(event) {
            let date = new Date();
            for(let trade of event.response) {
                trade.entryTime = parse(trade.entryTime, 'dd/MM/yyyy HH:mm:ss', date);
                trade.exitTime = parse(trade.exitTime, 'dd/MM/yyyy HH:mm:ss', date);
            }

            this.items = event.response;
        },
        getEnabledColumns() {
            let columns = [];

            for(let column of this.columns) {
                if(column.enabled) {
                    columns.push(column);
                }
            }

            return columns;
        },
        filterItems() {
            let filtered = this.items.filter(item => {
                return Object.keys(this.filters).every(key => String(item[key]).toLowerCase().includes(this.filters[key].toLowerCase()));
            });

            if(filtered.length > 0) {
                return filtered;
            } else {
                let emptyFilters = {};
                for(let key in this.filters) {
                    emptyFilters[key] = '';
                }

                return [];
            }
        },
        onColumnFilterClick(event, column) {
            let tagName = event.target.tagName.toLowerCase();

            if(tagName === 'input' || tagName === 'label') {
                return;
            }

            column.enabled = !column.enabled;
        },
        setupColumnFiltersModal() {
            this.filteredColumns = map(this.columns, clone);
        },
        finishColumnFilters() {
            this.columns = map(this.filteredColumns, clone);

            if(this.columnFiltersSave) {
                let columns = {};
                for(let column of this.columns) {
                    columns[column.key] = column.enabled;
                }

                localStorage.setItem('missedTradesColumns', JSON.stringify(columns));
            }
        },
        resetColumnFilters() {
            this.filteredColumns = [];
        },
        onExport() {
            let headers = {};

            for(let column of this.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.items, clone);
            for(let item of items) {
                item.entryTime = format(item.entryTime, 'yyyy-MM-dd HH:mm:ss');
                item.exitTime = format(item.exitTime, 'yyyy-MM-dd HH:mm:ss');
            }

            exportCsv(headers, items, 'MissedTrades');
        }
    }
}
</script>